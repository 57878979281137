import React from 'react';

const MarianLogo = () => {
  return (
    <a
      href="/"
      className="navbar-brand p-0"
      style={{
        display: 'inline-block',
        cursor: 'pointer',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        borderRadius: '50%', // Ensures the logo remains circular
      }}
      data-toggle="tooltip"
      data-placement="bottom"
      title="Go to Home"
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'translateY(-2px)';
        e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.3)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'translateY(0)';
        e.currentTarget.style.boxShadow = 'none';
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/MarianLogo.svg`}
        alt="Home"
        style={{
          height: '50px',
          zIndex: 2,
          opacity: 0.9,
          borderRadius: '50%', // Ensures the logo remains circular
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', // Circular shadow for a 3D effect
          transition: 'opacity 0.3s ease, transform 0.3s ease',
        }}
        title="Home"
      />
    </a>
  );
};

export default MarianLogo;
