import React, { useState, useEffect } from "react";
import { useAuth } from "../../auth/AuthContext";
import adminMenu from "../../source/menuAdmin.json";
import userMenu from "../../source/menuUser.json";
import guestMenu from "../../source/menuGuest.json";

import UtilMethods from "../../util/UtilMethods";
import MarianLogo from "./MarianLogo"; // Import the MarianLogo component
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"; // Import icons

const MenuBar = () => {
  let events;
  if (process.env.REACT_APP_EVENTS_SOURCE) {
    events = require(process.env.REACT_APP_EVENTS_SOURCE);
  } else {
    events = require("../../source/events.json");
  }
  const { user, loading, logout, userType } = useAuth();
  const [isLive, setIsLive] = useState(false);
  const [activeRetreat, setActiveRetreat] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // State to track mobile menu

  useEffect(() => {
    const checkLiveStatus = async () => {
      const apiKey = process.env.YOUTUBE_API_KEY;
      const channelId = process.env.YOUTUBE_CHANNEL_ID;
      const apiUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&eventType=live&type=video&key=${apiKey}`;

      try {
        if (!apiKey || !channelId) return;
        const response = await fetch(apiUrl);
        const data = await response?.json();
        setIsLive(data.items && data.items.length > 0);
      } catch (error) {
        setIsLive(false);
      }
    };

    checkLiveStatus();
    getActiveRetreat();
    const intervalId = setInterval(checkLiveStatus, 600000);
    return () => clearInterval(intervalId);
  }, []);

  const filterMenuByUserType = (menu, userType) => {
    if (!userType) return []; // Return empty menu if no userType is provided

    const userTypes = new Set(userType.split(",")); // Convert to Set for fast lookup
    //console.log("userTypes", userTypes);

    const filteredMenu = {
      items: [],
    };

    menu.items.forEach((menuItem) => {
      const filteredSubItems = menuItem.subItems
        ? menuItem.subItems.filter((subItem) => {
            if (!subItem.userTypes) return true; // No restriction, show to all
            const allowedUserTypes = new Set(subItem.userTypes.split(","));
            const isVisible = [...userTypes].some((type) =>
              allowedUserTypes.has(type)
            );
            /* console.log(
              `Subitem ${subItem.name} is ${
                isVisible ? "visible" : "hidden"
              } for user types ${userType}`
            );*/
            return isVisible;
          })
        : [];

      if (filteredSubItems.length > 0) {
        filteredMenu.items.push({
          ...menuItem,
          subItems: filteredSubItems,
        });
      }
    });

    return filteredMenu;
  };

  const getMenuData = () => {
    const menuData = { items: [...guestMenu.items] };
    menuData.items.push({
      name: "Live",
      link: "https://www.youtube.com/@FRDOMINICVALANMANALOFFICIAL/Live",
      type: "single",
      live: isLive,
      external: true,
    });
    if (activeRetreat) {
      menuData.items.push({
        name: `${activeRetreat.name.substring(0, 23)}`, // Set fixed length
        link: `/retreat-registration?id=${activeRetreat.id}`,
        type: "single",
        live: true,
        bold: true,
        glow: true,
        color: "orange", // Add orange color
      });
    }

    if (!user) return menuData;

    const menuByUserType = {
      A: adminMenu.items,
      U: userMenu.items,
    };

    const userFilteredAdminMenu = filterMenuByUserType(adminMenu, userType);
    menuData.items.push(...userFilteredAdminMenu.items);
    return menuData;
  };

  const getActiveRetreat = () => {
    const activeEvent = events.find((event) =>
      UtilMethods.canRegister(
        event.reg_date,
        event.reg_end_date ? event.reg_end_date : event.start_date
      )
    );
    // UtilMethods.log("info","MenuPanel.jsx - Line 61: activeEvent", activeEvent);
    setActiveRetreat(activeEvent);
  };

  const menuData = getMenuData();

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  if (loading) {
    return (
      <nav className="navbar navbar-expand-lg navbar-light px-4 py-2">
        <div className="d-flex justify-content-center align-items-center w-100">
          <Spinner animation="border" role="status">
            <span>Loading...</span>
          </Spinner>
        </div>
      </nav>
    );
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light px-4 py-2">
      <MarianLogo />
      <button
        className="navbar-toggler"
        type="button"
        onClick={handleToggle} // Toggle menu on click
        aria-controls="navbarCollapse"
        aria-expanded={isExpanded}
        aria-label="Toggle navigation"
      >
        <FontAwesomeIcon icon={isExpanded ? faTimes : faBars} />
      </button>
      <div
        className={`collapse navbar-collapse ${isExpanded ? "show" : ""}`}
        id="navbarCollapse"
      >
        <div className="navbar-nav ms-auto">
          {menuData.items.map((item) =>
            item.type === "single" ? (
              <a
                href={item.link}
                className={`nav-item nav-link ${item.live ? "blink" : ""} ${
                  !item.color ? "nav-link" : ""
                }`}
                key={item.name}
                target={item.external ? "_blank" : "_self"}
                rel={item.external ? "noopener noreferrer" : ""}
                style={{
                  fontWeight: item.bold ? "bold" : "normal",
                  textShadow: item.glow ? "0 0 8px orange" : "none",
                }}
              >
                {item.name}
              </a>
            ) : (
              <div className="nav-item dropdown" key={item.name}>
                <a
                  href="/"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {item.name}
                </a>
                <ul className="dropdown-menu m-0">
                  {item.subItems.map((subItem) => (
                    <li key={subItem.name}>
                      <a href={subItem.link} className="dropdown-item">
                        {subItem.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )
          )}
          {user ? (
            <a
              href="/logout"
              className="nav-item nav-link"
              onClick={(e) => {
                e.preventDefault();
                console.log("Logout Menu clicked");
                logout();
              }}
            >
              Logout
            </a>
          ) : (
            <div className="nav-item dropdown">
              <a
                href="/"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Login
              </a>
              <ul className="dropdown-menu m-0">
                <li>
                  <a href="/login" className="dropdown-item">
                    Login
                  </a>
                </li>
                <li>
                  <a href="/signup" className="dropdown-item">
                    Sign Up
                  </a>
                </li>
                <li>
                  <a href="/forgotPassword" className="dropdown-item">
                    Forgot Password
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default MenuBar;
