import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-phone-number-input/style.css";

import { AuthProvider } from "./auth/AuthContext";
import { useAuth } from "./auth/AuthContext";
import AutoLogout from "./page/admin/AutoLogout"; // AutoLogout component

// Lazy imports
const FrDominicValanmanalPage = lazy(() =>
  import("./page/about/FrDominicValanmanalPage")
);
const FrDominicValanmanalVideoPage = lazy(() =>
  import("./page/about/FrDominicValanmanalVideoPage")
);
const GraceAnointingPage = lazy(() =>
  import("./page/about/GraceAnointingPage")
);
const MarianRetreatCentrePage = lazy(() =>
  import("./page/about/MarianRetreatCentrePage")
);
const WorldEvangelizationPage = lazy(() =>
  import("./page/about/WorldEvangelizationPage")
);
const Contact = lazy(() => import("./page/ContactPage"));
const WaiverForm = lazy(() => import("./page/common/WaiverForm"));
const DeliveranceDetails = lazy(() => import("./page/DeliveranceDetailsPage"));
const HomePanel = lazy(() => import("./page/Home"));
const PrayerRequestPage = lazy(() =>
  import("./page/ministries/PrayerRequestPage")
);
const ListSabbathRegistrationPage = lazy(() =>
  import("./page/ministries/ListSabbathRegistrationPage")
);
const SabbathRegistrationPage = lazy(() =>
  import("./page/ministries/SabbathRegistrationPage")
);
const ConfessionPage = lazy(() => import("./page/resources/ConfessionPage"));
const PrayersPage = lazy(() => import("./page/resources/PrayersPage"));
const WOGPage = lazy(() => import("./page/resources/WOGPage"));
const MinistriesPage = lazy(() => import("./page/ministries/MinistriesPage"));
const FirstSaturdayPage = lazy(() =>
  import("./page/ministries/FirstSaturdayPage")
);
const EventRegistrationPage = lazy(() =>
  import("./page/retreat/EventRegistrationPage")
);
const AdminRetreatRegistrationPage = lazy(() =>
  import("./page/retreat/AdminRetreatRegistrationPage")
);
const GroupReport = lazy(() => import("./page/retreat/GroupReport"));
const SignUpPage = lazy(() => import("./page/admin/SignUpPage"));
const LoginPage = lazy(() => import("./page/admin/LoginPage"));
const Logout = lazy(() => import("./page/admin/Logout"));
const ForgetPasswordPage = lazy(() =>
  import("./page/admin/ForgetPasswordPage")
);
const AdminRoute = lazy(() => import("./page/admin/AdminRoute"));
const UserRoute = lazy(() => import("./page/admin/UserRoute"));
const ListUserPage = lazy(() => import("./page/admin/ListUserPage"));
const EmailSenderPage = lazy(() => import("./page/admin/EmailSenderPage"));
const ListPrayerRequestPage = lazy(() =>
  import("./page/ministries/ListPrayerRequestPage")
);
const ListPrayerRequestSummaryPage = lazy(() =>
  import("./page/ministries/ListPrayerRequestSummaryPage")
);
const ListPrayerRequestSummaryTilePage = lazy(() =>
  import("./page/ministries/ListPrayerRequestSummaryTilePage")
);
const ListPrayerRequestTablePage = lazy(() =>
  import("./page/ministries/ListPrayerRequestTablePage")
);
const OAuthCallback = lazy(() => import("./page/ministries/OAuthCallback"));

const EventsDetailsPage = lazy(() =>
  import("./page/retreat/EventsDetailsPage")
);

function App() {
  const { userType } = useAuth();
  // Set timeout based on user type
  const timeout =
    userType === "G" ? 900000 : userType === "U" ? 1800000 : 3600000; // 15 minutes, 30 minutes, 1 hour
  console.log("NODE_ENV:", process.env.NODE_ENV);
  console.log("REACT_APP_URL:", process.env.REACT_APP_URL);
  console.log("REACT_APP_BASENAME:", process.env.REACT_APP_BASENAME);
  return (
    <AuthProvider>
      <Router basename={process.env.REACT_APP_BASENAME}>
        {/* Wrap AutoLogout inside Router */}
        <AutoLogout timeout={timeout}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePanel />} />
              <Route path="/events" element={<EventsDetailsPage />} />
              <Route path="/events/:type" element={<EventsDetailsPage />} />
              <Route
                path="/retreat-registration/:id"
                element={<EventRegistrationPage />}
              />
              <Route
                path="/retreat-registration"
                element={<EventRegistrationPage />}
              />
              <Route path="/waiver-form" element={<WaiverForm />} />

              <Route
                path="/admin_retreat-registration"
                element={
                  <AdminRoute allowedUserTypes={["A", "R"]}>
                    <AdminRetreatRegistrationPage />
                  </AdminRoute>
                }
              />
              <Route path="/callback" element={<OAuthCallback />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/FrDominic" element={<FrDominicValanmanalPage />} />
              <Route
                path="/FrDominicV"
                element={<FrDominicValanmanalVideoPage />}
              />
              <Route path="/Marian" element={<MarianRetreatCentrePage />} />
              <Route path="/Grace" element={<GraceAnointingPage />} />
              <Route path="/firstSaturday" element={<FirstSaturdayPage />} />
              <Route
                path="/Evangelization"
                element={<WorldEvangelizationPage />}
              />
              <Route path="/deliverance" element={<DeliveranceDetails />} />
              <Route path="/sabbath" element={<SabbathRegistrationPage />} />
              <Route
                path="/listSabbathReq"
                element={
                  <AdminRoute allowedUserTypes={["A", "S", "D"]}>
                    <ListSabbathRegistrationPage />
                  </AdminRoute>
                }
              />
              <Route path="/prayer" element={<PrayerRequestPage />} />
              <Route path="/prayers" element={<PrayersPage />} />
              <Route path="/wog" element={<WOGPage />} />
              <Route path="/confession" element={<ConfessionPage />} />
              <Route path="/ministries" element={<MinistriesPage />} />
              <Route
                path="/listPrayerRequest"
                element={
                  <AdminRoute allowedUserTypes={["P", "D"]}>
                    <ListPrayerRequestPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/listPrayerRequestSummary"
                element={
                  <AdminRoute allowedUserTypes={["D"]}>
                    <ListPrayerRequestSummaryPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/listPrayerRequestSummaryTile"
                element={
                  <AdminRoute allowedUserTypes={["D"]}>
                    <ListPrayerRequestSummaryTilePage />
                  </AdminRoute>
                }
              />
              <Route
                path="/listPrayerRequestTable"
                element={
                  <AdminRoute allowedUserTypes={["P"]}>
                    <ListPrayerRequestTablePage />
                  </AdminRoute>
                }
              />
              <Route
                path="/listRegistration"
                element={
                  <AdminRoute allowedUserTypes={["A", "R"]}>
                    <GroupReport />
                  </AdminRoute>
                }
              />
              <Route
                path="/listUser"
                element={
                  <AdminRoute allowedUserTypes={["A"]}>
                    <ListUserPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/sendEmail"
                element={
                  <AdminRoute allowedUserTypes={["A", "R"]}>
                    <EmailSenderPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/signup"
                element={
                  <UserRoute>
                    <SignUpPage />
                  </UserRoute>
                }
              />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/forgotPassword" element={<ForgetPasswordPage />} />
              <Route path="*" element={<HomePanel />} />
            </Routes>
          </Suspense>
        </AutoLogout>
      </Router>
    </AuthProvider>
  );
}

export default App;
