import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext"; // Assuming you have an AuthContext
import { jwtDecode } from "jwt-decode"; // Correct named import

const AutoLogout = ({ timeout = 3600000, children }) => {
  const lastActivityRef = useRef(Date.now());
  const navigate = useNavigate();
  const { logout } = useAuth(); // Assuming logout method from AuthContext

  // Function to check if the token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const { exp } = jwtDecode(token);
      return Date.now() >= exp * 1000; // Convert exp to milliseconds
    } catch (e) {
      console.error("Failed to decode token:", e);
      return true; // Treat decoding failures as expired
    }
  };

  useEffect(() => {
    const resetActivityTimer = () => {
      lastActivityRef.current = Date.now();
    };

    // Listen for user activity
    window.addEventListener("mousemove", resetActivityTimer);
    window.addEventListener("keydown", resetActivityTimer);
    window.addEventListener("click", resetActivityTimer);

    // Periodic check for inactivity
    const checkInactivity = setInterval(() => {
      if (Date.now() - lastActivityRef.current > timeout) {
        console.log("User inactive. Logging out...");
        logout();
        navigate("/login", { replace: true });
      }
    }, 1000); // Check every second

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("mousemove", resetActivityTimer);
      window.removeEventListener("keydown", resetActivityTimer);
      window.removeEventListener("click", resetActivityTimer);
      clearInterval(checkInactivity);
    };
  }, [timeout, logout, navigate]);

  useEffect(() => {
    // Check token expiration on mount
    const token = localStorage.getItem("token");
    if (token && isTokenExpired(token)) {
      console.log("Token expired. Logging out...");
      logout();
      navigate("/login", { replace: true });
    }
  }, [logout, navigate]);

  return <>{children}</>;
};

export default AutoLogout;
