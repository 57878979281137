import React, { createContext, useState, useContext, useEffect } from "react";

// Create the AuthContext
const AuthContext = createContext();

/**
 * AuthProvider
 * Manages user and Zoom token state, persisting them in localStorage.
 */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [token, setToken] = useState(null);
  const [guestToken, setGuestToken] = useState(null);
  //zoom Token
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [expiryTime, setExpiryTime] = useState(null);
  const [loginExpiryTime, setLoginExpiryTime] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("user"));
      const storedData = JSON.parse(localStorage.getItem("zoomToken"));

      if (storedData) {
        const { access_token, refresh_token, expiryTime } = storedData;

        // Validate token expiry
        if (expiryTime > Date.now()) {
          setAccessToken(access_token);
          setRefreshToken(refresh_token);
          setExpiryTime(expiryTime);
        } else {
          console.warn("Zoom access token expired. Consider refreshing.");
        }
      }

      if (storedUser) {
        setUser(storedUser);
        setUserType(storedUser.userType);
      }
    } catch (error) {
      console.error("Error loading from localStorage:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Debugging: Log state changes (optional, development only)
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("AuthContext state:", user);
    } else {
      if (user) {
        console.log("AuthContext state is not null:");
      } else {
        console.log("AuthContext state is null:");
      }
    }
  }, [user, token, accessToken, refreshToken, expiryTime]);

  const login = ({ userData, token }) => {
    if (!userData || !token) {
      console.error("Invalid parameters passed to login:", { userData, token });
      return;
    }

    if (process.env.NODE_ENV === "development") {
      console.log("Initial AuthContext state:", {
        user,
        token,
        accessToken,
        refreshToken,
        expiryTime,guestToken
      });
      console.log("Parameters received:", { userData, token });
    }

    // Update state
    setUser(userData);
    setUserType(userData.userType);
    setToken(token);
    setGuestToken(null);

    // Persist in localStorage
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("token", token);
    localStorage.removeItem("guestToken");

    console.log("Login operation completed. User logged in.");
    if (process.env.NODE_ENV === "development") {
      console.log("Updated AuthContext state:", {
        user,
        token,
        accessToken,
        refreshToken,
        expiryTime,guestToken
      });
    }
  };
  // Remove search parameters from the URL
  const removeSearchParams = () => {
    console.log("Removing search parameters from the URL...");
    const url = new URL(window.location);
    url.search = "";
    window.history.replaceState({}, document.title, url.toString());
  };

  const logout = () => {
    console.log("Logging out...");
    window.location.href = "/";
    setUser(null);
    setUserType(null);
    setToken(null);
    setGuestToken(null);
    setAccessToken(null);
    setRefreshToken(null);
    setExpiryTime(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("guestToken");
    localStorage.removeItem("zoomToken");
    // Call the function to remove search parameters
    removeSearchParams();
    console.log("User logged out.");
    if (process.env.NODE_ENV === "development") {
      console.log("Updated AuthContext state logout:", {
        user,
        token,
        accessToken,
        refreshToken,
        expiryTime,
      });
    }
  };

  /**
   * Update Zoom tokens and their expiry time.
   * @param {string} accessToken - New access token.
   * @param {string} refreshToken - New refresh token.
   * @param {number} expiresIn - Token validity duration in seconds.
   */
  const updateZoomToken = (accessToken, refreshToken, expiresIn) => {
    const expiryTime = Date.now() + expiresIn * 1000;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setExpiryTime(expiryTime);

    const tokenData = {
      access_token: accessToken,
      refresh_token: refreshToken,
      expiryTime,
    };
    localStorage.setItem("zoomToken", JSON.stringify(tokenData));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userType,
        token,
        accessToken,
        refreshToken,
        expiryTime,
        loading,
        login,
        logout,
        updateZoomToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Custom hook to use the AuthContext.
 */
export const useAuth = () => useContext(AuthContext);
